.scroll-button-container {
  position: fixed;
  top: 85%;
  right: 100px;
  transition: 0.5s;
  height: auto;
  z-index: 99;
}
.scroll-button-hidden-container {
  position: fixed;
  top: 85%;
  right: 0;
  transition: 0.5s;
  height: 50%;
}

.scroll-button {
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.scroll-button-wrapper {
  width: 100%;
  transition: 0.5s;
  margin-left: 100%;
}
.scroll-button-wrapper.is-nav-open {
  position: absolute;
  top: 85%;
  margin-right: 0;
  transition: 0.5s;
}
.is-nav-open {
  margin-right: 0;
  border: solid 1px;
  border-radius: 3px;
  transition: 0.5s;
  background: white;
}
@media (max-width: 540px) {
  .scroll-button-container {
    position: fixed;
    top: 85%;
    right: 65px;
    transition: 0.5s;
    height: auto;
    z-index: 99;
  }
}

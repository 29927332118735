.who-are-we-button {
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 145px;
  height: 40px;
  border: 1px solid;
  box-sizing: border-box;
  border-radius: 30.1967px;
  background-color: transparent;
  outline: none;
  margin-top: 5%;
  margin-bottom: 1rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.who-are-we-button {
  z-index: 99;
  cursor: pointer;
}

.who-are-we-button-wrapper {
  display: flex;
  flex-wrap: nowrap;
  /* align-content: center; */
  margin-top: -20px;
  flex-direction: row;
  /* border: 1px solid; */
  align-items: baseline;
}
.who-are-we-button-img {
  margin-top: 3px;
}
.who-are-we-button-text {
  font-size: 18px;
  padding: 0px 7px 0px 9px;
}

@media (max-width: 800px) {
  .who-are-we-button-text {
    font-size: 13px;
    padding: 0px 7px 1px 9px;
  }
}

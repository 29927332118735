.black-banner-container {
  height: 30vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  align-content: center;
  align-items: center;
}
.black-banner-text-wrapper {
  height: 20vh;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-self: center;
  align-content: center;
  align-items: center;
  position: relative;
  color: white;
  text-align: center;
}
.black-banner-wrapper {
  display: flex;
  flex-direction: row;
  align-self: center;
  align-content: center;
  align-items: center;
}
.black-banner-button {
  background-color: transparent;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
  text-decoration: none;
  width: 124px;
  height: 40px;
  cursor: pointer;
  color: #ffffff;
  outline: none;
  position: relative;
  right: 10%;
}

.black-banner-subtitle {
  width: 50%;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 112%;
  text-align: left;
  color: #ffffff;
}

.black-banner-subtitle2 {
  width: 50%;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
  text-align: left;
}
@media (max-width: 1024px) {
  .black-banner-subtitle {
    font-size: 24px;
  }
  .black-banner-subtitle2 {
    font-size: 22px;
  }
}
@media (max-width: 540px) {
  .black-banner-wrapper {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-content: center;
    align-items: center;
  }
  .black-banner-subtitle {
    width: 95%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 112%;
    text-align: left;
    color: #ffffff;
    padding-bottom: 1rem;
  }
  .black-banner-subtitle2 {
    width: 95%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    color: #ffffff;
    text-align: left;
    padding-bottom: 1rem;
  }
  .black-banner-button {
    background-color: transparent;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 15px;
    text-decoration: none;
    width: 100px;
    height: 30px;
    cursor: pointer;
    color: #ffffff;
    outline: none;
  }
}

.full-screen-container {
  width: 100%;
}
.full-screen-card-image {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  align-self: center;
  align-content: center;
  height: 100vh;
}
.full-screen-right-card-image {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  align-items: center;
  align-self: center;
  align-content: center;
  height: 100vh;
}
.full-screen-img {
  height: 100%;
  border-radius: 20px;
}
.full-screen-image {
  height: 70%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.full-screen-right-image {
  height: 70%;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  right: 5%;
}
.full-screen-text-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #ffff;
}
.full-screen-right-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  align-content: center;
  position: relative;
  left: 10%;
}
.full-screen-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  position: relative;
  right: 10%;
}
.full-screen-title {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  width: 100%;
}
.full-screen-subtitle {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  width: 50%;
  margin-bottom: 5%;
}
.full-screen-description {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  width: 80%;
  margin-bottom: 2%;
  text-align: left;
}

.full-screen-vector {
  width: 45%;
  position: absolute;
  left: 80%;
}
.full-screen-right-vector {
  width: 45%;
  position: absolute;
  right: 80%;
}
@media (max-height: 1100px) {
  .full-screen-container {
    height: 129vh !important;
  }
}
@media (max-width: 1024px) {
  .full-screen-container {
    height: 112vh;
  }
  .full-screen-card-image {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    width: 100%;
    height: 110vh;
    margin-bottom: 5%;
  }
  .full-screen-img {
    height: 100%;
    border-radius: 20px;
    width: 100%;
  }
  .full-screen-image {
    height: 70%;
    width: 100%;
    position: relative;
    left: 0px;
  }
  .full-screen-text-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 10%;
    height: 85%;
  }
  .full-screen-text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    position: relative;
    right: 0px;
    text-align: center;
    height: 85%;
  }
  .full-screen-title {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-size: 32px;
    line-height: 25px;
    width: 80%;
    margin-bottom: 4%;
  }
  .full-screen-subtitle {
    width: 70%;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 4%;
  }
  .full-screen-description {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    width: 100%;
    margin-bottom: 2%;
    text-align: center;
  }
  .full-screen-right-card-image {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    width: 100%;
    height: 110vh;
    margin-bottom: 5%;
  }
  .full-screen-right-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
    align-content: center;
    text-align: center;
    width: 80%;
    position: relative;
    left: 0px;
    height: 85%;
  }
  .full-screen-vector {
    width: 60%;
    position: absolute;
    left: 52%;
  }
  .full-screen-right-vector {
    width: 60%;
    position: absolute;
    right: 55%;
  }
  .full-screen-right-image {
    height: 70%;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    right: 0;
  }
}

@media (max-width: 540px) {
  .full-screen-container {
    width: 100%;
  }
  .full-screen-card-image {
    display: flex;
    flex-direction: column-reverse;
  }
  .full-screen-right-card-image {
    display: flex;
    flex-direction: column-reverse;
  }
  .full-screen-image {
    height: 50%;
  }
  .full-screen-right-image {
    height: 50%;
  }

  .full-screen-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 50%;
  }
  .full-screen-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    right: 0;
  }
  .full-screen-right-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    left: 0;
  }
  .full-screen-title {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    width: 100%;
    text-align: center;
    margin-top: 5%;
  }
  .full-screen-subtitle {
    width: 70%;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
  }
  .full-screen-description {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    width: 80%;
    margin-bottom: 2%;
    text-align: center;
  }
  .full-screen-vector {
    width: 45%;
    position: absolute;
    left: 55%;
  }
  .full-screen-right-vector {
    width: 45%;
    position: absolute;
    right: 60%;
  }
}

.background {
  z-index: -10;
}
/* Dropdown menü filter*/
.authorlist {
  display: flex;
  justify-content: space-around;
  padding-top: 150px;
}

.authorlist-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  align-content: center;
  align-items: center;
  width: 90%;
}
.authorlist-filter-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-self: center;
  align-content: center;
  align-items: center;
  position: relative;
  color: white;
  text-align: center;
}

.authorlist-title {
  text-align: justify;
  display: flex;
  width: 50%;
}

.authorlist-dropdown {
  color: white;
  float: left;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 20px;
}
.dropbtn {
  background-color: transparent;
  color: white;
  padding: 12px;
  margin-left: 15px;
  font-size: 12px;
  border: 1px solid white;
  border-radius: 30px;
  cursor: pointer;
  width: 160px;
  text-align: left;
}
.dropbtnara {
  background-color: transparent;
  color: white;
  padding: 12px;
  margin-left: 15px;
  font-size: 12px;
  border: 1px solid white;
  border-radius: 30px;
  cursor: pointer;
  width: 100px;
  text-align: center;
}
.dropbtnaratext {
  background-color: transparent;
  color: white;
  padding: 12px;
  margin-left: 15px;
  font-size: 12px;
  border: 1px solid white;
  border-radius: 30px;
  cursor: pointer;
  width: 200px;
  text-align: left;
}
.authorlist-blook {
  width: 65vh;
}
.dropimg {
  float: right;
  width: 17px;
}
.ui.selection.active.dropdown .menu {
  border-color: transparent;
  margin-top: 3px;
  border-radius: 3px;
}

.ui.selection.dropdown .menu > .item {
  border-top: 0px;
}
.selected.item {
  border: 1px solid;
  background: white;
}
.dropdown {
  position: relative;
  display: inline-block;
  padding: 10px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}
.ui.active.visible.selection.dropdown.dropbtn {
  border-radius: 30px !important;
}
.ui.selection.dropdown {
  cursor: pointer;
  word-wrap: break-word;
  line-height: 1em;
  white-space: normal;
  outline: 0;
  transform: rotateZ(0);
  min-width: 14em;
  color: white !important;
  height: 40px;
  border-radius: 30px !important;
  background: transparent !important;
  border: 1px solid white !important;
}
.divider.text {
  color: white !important;
  margin-top: 3px !important;
  font-size: 16px;
}

.chevron.up.icon {
  float: right;
  width: 20px !important;
  height: 14px !important;
  margin-top: 5px !important;
  margin-right: -10px !important;
  height: 14px !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.ui.dropdown .menu > .item:hover {
  color: black !important;
}

/* ------------------------- */

/*BOOOK LİST*/

.author-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 0;
  right: 0;
  align-items: center;
  margin-left: 7%;
}
.author-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
}
.authors-list {
  margin: 40px;
  width: 200px;
  height: 307px;
  margin-top: 70px;
  align-items: left;
}
.authors-list-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.author-undefined-container {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.author-undefined-img {
  width: 200px;
  height: 200px;
  text-align: center;
}
.author-undefined-text {
  text-align: center;
  color: white;
  position: absolute;
  width: 200px;
  font-size: 20px;
  font-weight: bold;
}

.authors-img {
  width: 200px;
  height: 200px;
  align-self: center;
  object-fit: cover;
  cursor: pointer;
  border-radius: 222px;
  box-shadow: rgba(255, 254, 254, 0.185) 0px 8px 24px;
}

.authors-img .img {
  border: 10px solid red;
}
.authors-info {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 5px;
  text-align: center;
  align-self: center;
  word-break: break-word;
  table-layout: fixed;
  width: 200px;
  color: white;
  text-overflow: ellipsis;
}
.author {
  font-weight: bold;
}
.authorname {
  font-size: large;
  margin-left: 5px;
}
.subject {
  font-weight: bold;
}
/* ------------ */

/* authorlist respons */
@media (min-width: 960px) {
  .bize-ayrilan-bolge {
    padding-right: 100px;
  }
}

@media (min-width: 1120px) {
  .author-wrapper {
    width: 100%;
  }
}
@media (max-width: 1420px) {
  .author-wrapper {
    width: 100%;
    grid-template-columns: 20% 20% 20% 20% 20%;
  }

  .authors-img {
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 1200px) {
  .author-wrapper {
    width: 100%;
    grid-template-columns: 20% 20% 20% 20% 20%;
  }
  .author-container {
    margin-left: 1% !important;
  }
}
@media (max-width: 960px) {
  .author-modals-backicon {
    width: 4% !important;
  }
  .author-wrapper {
    width: 100%;
    grid-template-columns: 30% 30% 30%;
  }

  .authors-img {
    width: 140px;
    height: 140px;
  }
  .author-undefined-text {
    font-size: 17px;
    width: 120px;
  }
  .author-container {
    padding-top: 20px;
    margin-left: 5% !important;
  }
}
@media (max-width: 700px) {
  .authors-img {
    width: 120px;
    height: 120px;
  }
  .authors-info {
    width: 120px;
  }
  .author-container {
    padding-top: 20px;
    margin-left: 20% !important;
  }
  .author-wrapper {
    grid-template-columns: 50% 50%;
    font-size: 10px;
  }
  .authors-list {
    max-width: 110px !important;
    margin-left: 10px !important;
    height: 248px !important;
    margin-top: 20px !important;
  }
}
@media (max-width: 430px) {
  .author-container {
    margin-left: 10% !important;
  }
}
@media (max-width: 380px) {
  .author-wrapper {
    grid-template-columns: 40% 40%;
    font-size: 10px;
  }
  .authors-list {
    max-width: 110px !important;
    margin-left: 15% !important;
    height: 248px !important;
    margin-top: 20px !important;
  }
}
/* ------------ */
@media (max-width: 960px) {
  /* FİLTER MENÜ */
  .dropbtn {
    width: 120px !important;
  }
  .dropdown {
    padding: 0px;
    padding-left: 10px;
    margin: 0px;
    left: 0;
    right: 0px;
  }
  .divider.text {
    font-size: 10px;
  }
  .authorlist-dropdown {
    justify-content: center;
  }
  .authorlist-filter-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .authorlist-title {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .dropbtnara {
    position: absolute;
    opacity: 0;
    z-index: -3;
  }

  .dropbtnaratext {
    position: absolute;
    opacity: 0;
    z-index: -3;
  }
  .dropbtn_mobilicon {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-align: center;

    margin-left: 0px;
  }
  .dropimg1 {
    width: 30px;
  }
  .dropbtnaratext-mobil {
    background-color: transparent;
    color: white;
    padding: 12px;
    font-size: 12px;
    border: 1px solid white;
    border-radius: 30px;
    cursor: pointer;
    width: 95%;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
  }

  .text span {
    border: 1px solid;
  }

  .ui.dropdown .menu.item {
    border: 10px solid;
  }
  .ui.selection.dropdown {
    min-width: 1em !important;
  }
  /* ------------------ */
}
@media (min-width: 960px) {
  .dropbtn_mobilicon {
    position: absolute;
    opacity: 0;
    z-index: -3;
  }
}
.modal-container {
  position: absolute;
  width: 1000px;
  height: 609px;
  left: 220px;
  flex-direction: column;
  display: flex;

  background: rgba(249, 249, 249, 0.9);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
}
.moodal-css {
  border: 10px solid;
  background-color: rgba(249, 249, 249, 0.9);
}

/* pagenation */
.pagenation-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  padding-top: 100px;
  padding-bottom: 150px;
}
.pagenation-wrapper {
  align-items: center;
}
.ui.pagination.menu {
  margin: 0;
  display: inline-flex;
  vertical-align: middle;
  background-color: transparent;
  border-radius: 100px;
  border: transparent;
}
.ui.pagination.menu .item {
  color: white;
}
.ui.pagination.menu .item :active {
  background-color: white;
}
.active :active {
  color: white;
}
.item {
  color: white;
  margin-left: 10px !important;
}

.ui.menu {
  box-shadow: none !important;
}
.ui.pagination.menu .active.item {
  background-color: white !important;
  border-radius: 100px;
}
.item:hover {
  color: white !important;
}
.item {
  border-radius: 100px !important;
  border: 1px solid white;
}
.pagenation {
  border: 1px solid red;
}
.menu {
  border: 1px solid red;
}

/*MODALLS*/
.modals-img {
  width: 200px;
  height: 307px;
  margin: 30px;
}
.modals-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.modals-wrapper {
  display: flex;
}
.modals-position {
  display: flex;
}
.image.content {
  height: 60vh;
  background: rgba(249, 249, 249, 0.9) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  backdrop-filter: blur(20px) !important;
}
.ui.modal {
  border-radius: 0px;
  background: transparent !important;
}
.author-modals-backicon {
  width: 2%;
  margin: 20px;
  object-fit: initial;
  cursor: pointer;
}
.modal-button-container {
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: space-between;
}
.models-button-text {
  display: flex;
  margin-top: -1px !important;
}
.modals-line {
  height: 35vh;
  display: flex;
  position: relative;
}
.models-center {
  width: 50%;
  display: flex;
}
.modal-button {
  width: 180px !important;
  margin-left: 30px !important;
  display: flex;
  color: white !important;
  border-radius: 15px !important;
  background-color: #4f4192 !important;
  font-family: 'Roboto';
  font-style: normal;
}
.modal-button-2 {
  width: 120px;
  margin-right: 35px !important;
  display: flex;
  color: white !important;
  border-radius: 15px !important;
  background-color: #4f4192 !important;
  font-family: 'Roboto';
  font-style: normal;
}
.modals-buttonicon {
  width: 18px;
  height: 18px;
  float: left;
  object-fit: cover;
  object-fit: revert;
}

.modals-text-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding-top: 50px;
}
.modals-text-container2 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding-top: 50px;
  padding-left: 30px;
}
.models-text {
  margin-bottom: 50px;
}
.ui.dimmer {
  background-color: transparent !important;
}

/* Modals Mobile */

@media (min-width: 801px) {
  .modal-mobilebutton-container {
    position: absolute;
    opacity: 0;
    z-index: -3;
  }
}
@media (max-width: 800px) {
  .ui.modal .image.content {
    padding: 0px !important;
    padding-top: 20px !important;
  }
  .image.content {
    height: 100vh;
    width: 100%;
    display: flex !important;
    background: none !important;
    box-shadow: 0px 0px 0px rgba(255, 255, 255, 0) !important;
    padding: 0px !important;
  }
  .modal-mobile-bg {
    position: fixed;
    top: 0 !important;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    background: white !important;
    animation-fill-mode: both;
    animation-duration: 0.5s;
    transition: background-color 0.5s linear;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
    user-select: none;
    will-change: opacity;
    z-index: 1000;
  }

  .active.moda {
    top: 0 !important;
  }
  .ui.modal > .content {
    background-color: transparent !important;
  }
  .ui.modal.transition.visible.active {
    top: 0;
    left: 0;
  }
  .modals-img {
    width: 120px !important;
    height: 208px !important;
  }

  .modals-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .ui.modal {
    box-shadow: none !important;
  }
  .author-modals-backicon {
    width: 35px !important;
    height: 25px;
    margin-left: 20px !important;
    cursor: pointer;
  }
  .modal-button-container {
    position: absolute;
    opacity: 0;
    z-index: -3;
  }
  .modals-line {
    position: absolute;
    opacity: 0;
    z-index: -3;
  }
  .models-center {
    left: 0;
    width: 100%;
  }
  .top {
    background-color: #f9f9f9 !important;
    width: 100%;
    padding-top: 20px !important;
    margin-top: 5px;
  }
  .bottom {
    margin-top: -60px;
  }

  .modals-text-container2 {
    width: 70%;
    padding-top: 100px;
  }

  .modal-mobilebutton-container {
    width: 91%;
    padding-left: 24px;
    padding-top: 20px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .modal-mobilebutton {
    width: 200px;
    height: 40px;
    font-size: 15px !important;
    display: flex;
    color: white !important;
    border-radius: 15px !important;
    background-color: #4f4192 !important;
    font-family: 'Roboto';
    font-style: normal;
  }
  .modals-mobilebuttonicon {
    width: 18px !important;
    float: left;
  }
  .modal-mobilebutton-2 {
    width: 120px;
    height: 40px;
    display: flex;
    color: white !important;
    border-radius: 15px !important;
    background-color: #4f4192 !important;
    font-family: 'Roboto';
    font-style: normal;
    font-size: 15px;
  }
  .modal-mobilebutton p {
    text-align: center;
  }
}

.bg-image {
  position: absolute;
  margin: 0px;
  right: 0px;
  padding-top: 400px;
  z-index: 0;
}
.bg-image2 {
  margin-top: -600px !important;
  z-index: -1;
  float: left;
}
.bg-author {
  background: rgb(255 255 255 / 96%) !important;
}
/* MOBILE BG */
@media (max-width: 800px) {
  .bg-image {
    width: 200px;
    padding-top: 200px;
    z-index: 0;
  }
  .bg-image2 {
    width: 200px;
  }
  .bg-author {
    margin-top: -14px;
    height: 100vh;
  }
}

.sonuc {
  width: 100%;
  height: 50vh;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}
.sonuc-title {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  color: white;
}
.ui.segment:last-child {
  height: 50vh;
  width: 100%;
  border-radius: 0px !important;
  background: black;
}
.loading-text {
  padding-top: 100px !important;
}
@media (max-height: 900px) {
  .black-banner-container {
    height: 80vh !important;
  }
}

/**/
.modal-author {
  width: 100% !important;
}
.author-books-modal {
  width: 100%;
}
.modals.dimmer .ui.scrolling.modal {
  width: 100% !important;
}

.hmbrg-menu-container {
  width: 100%;
  position: absolute;
  z-index: 9 !important;
}
.hmbrg-menu-logo {
  width: 38px;
  height: 25px;
  position: absolute;
  left: 5%;
  bottom: 25%;
  cursor: pointer;
}

.hmbrg-logo {
  width: 55px;
  height: 55px;
  position: relative;
  left: 45%;
  top: 1rem;
  cursor: pointer;
}
.hmbrg-menu-modal {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(23, 39, 86, 0.8);
  z-index: 10 !important;
}

.hmbrg-menu-close-logo {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 5%;
  top: 2.5%;
  cursor: pointer;
}
.hmbrg-menu-items {
  height: 100vh;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
}

.hmbrg-menu-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #ffffff !important;
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  padding-right: 1rem;
  cursor: pointer;
}

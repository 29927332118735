.slider-container-img {
  background: radial-gradient(50% 50% at 50% 50%, #2d427f 0%, #11204a 100%);
  height: 100vh;
  width: 100%;
  background-image: url('../images/BG.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.slider-right-card-image {
  display: flex;
  flex-direction: row-reverse;
}
.slider-right-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.slider-right-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
}
.slider-text-wrapper {
  width: 70%;
  display: flex;
  flex-direction: column;
  color: #ffffff;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 65px;
  width: 100%;
}
.main-subtitle {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 29.154px;
  line-height: 40px;
  width: 100%;
  padding-bottom: 0.5rem;
}
.main-subtitle2 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 36.1052px;
  line-height: 49px;
  padding-bottom: 3rem;
}

.main-description {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  width: 100%;
  padding-bottom: 1rem;
}
.main-description2 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  padding-bottom: 2rem;
}
.button {
  background-color: transparent;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
  text-decoration: none;
  width: 124px;
  height: 40px;
  cursor: pointer;
  color: #ffffff;
  outline: none;
}
.button-container {
  width: 124px;
  height: 40px;
}
.main-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}
.phone-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: relative;
}
.phone-image {
  height: 80vh;
  position: relative;
  left: 10%;
  background-color: transparent;
  border-radius: 50px;
}
.text-container {
  display: flex;
  flex-direction: column;
  height: 75%;
  color: #ffffff;
  width: 40%;
  font-family: 'Open Sans', sans-serif;
  align-items: center;
  text-align: center;
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 65px;
  margin-bottom: 1rem;
  width: 100%;
}
.subTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 65px;
  margin-bottom: 3rem;
}
.text1 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  width: 100%;
  margin-bottom: 1.5rem;
}
.text2 {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  width: 100%;
  margin-bottom: 2rem;
}
.download-button {
  width: 278px;
  height: 50px;
  border-radius: 72.4299px;
  text-decoration: none;
  border: none;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #382669;
  font-weight: 300;
  font-size: 20.2804px;
  line-height: 28px;
  cursor: pointer;
}
.download-button-text {
  font-size: 20.2804px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px;
  font-family: 'Open Sans';
  line-height: 28px;
  font-weight: 300;
  font-style: normal;
}
.icon {
  margin-right: 0.8rem;
}
@media (max-height: 1000px) {
  .main-subtitle {
  }
}
@media (max-width: 1024px) {
  .slider-right-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: fill;
    width: 50%;
  }
  .slider-container-img {
    background: radial-gradient(50% 50% at 50% 50%, #2d427f 0%, #11204a 100%);
    height: 100vh;
    width: 100%;
    background-image: url('../images/BG.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .slider-right-card-image {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .slider-right-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    align-self: left;
    align-content: left;
    height: 87.5vh;
    width: 50%;
    position: absolute;
    left: 5px;
    top: 7.5%;
  }
  .slider-text-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: #ffffff;
    text-align: center;
    position: relative;
    bottom: 10%;
    left: 5%;
  }
  .slider-right-mobil-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: fill;
    height: 100vh;
  }
  .slider-right-mobil-image-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 80vh;
    width: 70%;
    margin-top: 25%;
    position: absolute;
    right: -10%;
    bottom: 10%;
  }
  .main-title {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 45px;
    width: 100%;
    text-align: left;
  }

  .main-subtitle {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    width: 90%;
    padding: 0px;
    line-height: 29px;
  }
  .main-subtitle.left {
    text-align: left;
  }
  .main-description {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    text-align: left;
    padding-top: 1rem;
    padding-bottom: 5px;
  }
  .main-description2 {
    text-align: left;
    font-size: 16px;
    padding-bottom: 10px;
  }
  .download-button {
    width: 165px;
    height: 40px;
    border-radius: 72.4299px;
    text-decoration: none;
    border: none;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #382669;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    cursor: pointer;
    position: relative;
    top: 2%;
  }
  .main-button-container {
    width: 20rem;
  }
  .main-button-container {
    display: flex;
    flex-direction: row;
    width: 90%;
  }
  .phone-image-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: relative;
  }
  .phone-image {
    height: 65vh;
    position: absolute;
    left: 5%;
    background-color: transparent;
    border-radius: 65px;
  }
  .text-container {
    display: flex;
    flex-direction: column;
    height: 75%;
    color: #ffffff;
    width: 40vw;
    font-family: 'Open Sans', sans-serif;
    position: relative;
  }
  .main-subtitle {
    font-size: 22px;
    padding: 0px;
  }
  .main-subtitle2 {
    font-size: 42px;
    height: auto;
    padding: 0px;
    text-align: left;
  }
  .text1 {
    font-size: 24px;
    line-height: 35px;
  }
  .text2 {
    font-size: 22px;
    line-height: 35px;
  }
}

@media (max-width: 540px) {
  .slider-container-img {
    background: radial-gradient(50% 50% at 50% 50%, #2d427f 0%, #11204a 100%);
    height: 100vh;
    width: 100%;
    background-image: url('../images/BG.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .slider-right-card-image {
    display: flex;
    flex-direction: column;
  }
  .slider-right-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: left;
    align-self: left;
    align-content: left;
    height: 87.5vh;
    width: 50%;
    position: absolute;
    left: 5px;
  }
  .slider-text-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: #ffffff;
    text-align: center;
  }
  .slider-right-mobil-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: fill;
  }
  .slider-right-mobil-image-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 55vh;
    width: 70%;
    margin-top: 25%;
    position: absolute;
    right: 0px;
    bottom: 30%;
  }
  .main-title {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    width: 100%;
    line-height: 85px;
    height: 7.5vh;
    padding: 0px;
    text-align: left;
    padding: 0px;
  }
  .main-subtitle {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 29px;
    width: 80%;
    padding: 0px;
  }
  .main-subtitle2 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 49px;
    margin-bottom: 1px;
    padding: 0px;
    text-align: left;
  }
  .main-description {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    width: 60vw;
    position: relative;
    right: 1%;
    padding: 0px;
    top: 5%;
  }

  .main-description2 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    width: 60vw;
    position: relative;
    top: 7.5%;
  }
  .download-button {
    width: 165px;
    height: 40px;
    border-radius: 72.4299px;
    text-decoration: none;
    border: none;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #382669;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    cursor: pointer;
  }
  .main-button-container {
    width: 98vw;
    position: relative;
    top: 10%;
  }
  .button {
    background-color: transparent;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 15px;
    text-decoration: none;
    width: 100px;
    height: 30px;
    cursor: pointer;
    color: #ffffff;
    outline: none;
  }
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 65px;
    width: 90%;
    margin: 0px;
    padding: 0px;
  }
  .phone-image-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: relative;
  }
  .phone-image {
    height: 55vh;
    position: absolute;
    left: -15%;
    background-color: transparent;
    border-radius: 25px;
  }
  .text1 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    width: 60vw;
    margin-bottom: 1.5rem;
    position: relative;
    top: 10%;
  }
  .text2 {
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    width: 100%;
    margin-bottom: 2rem;
    position: relative;
    top: 10%;
  }
  .text-container {
    display: flex;
    flex-direction: column;
    height: 75%;
    color: #ffffff;
    width: 60vw;
    font-family: 'Open Sans', sans-serif;
    position: relative;
  }
  .button-container {
    width: 124px;
    height: 40px;
    position: relative;
    top: 10%;
  }
}

@media (max-width: 1020px) {
  .phone-image {
    height: 55vh;
    position: absolute;
    left: 15%;
    background-color: transparent;
    border-radius: 35px;
  }
  .title {
    font-size: 48px;
  }
  .main-subtitle {
  }
  .main-subtitle2 {
    font-size: 24px;
  }
  .text1 {
    font-size: 20px;
  }
  .text2 {
    font-size: 15px;
  }
  .download-button-text {
    font-size: 15px;
  }
}

@media (max-width: 790px) {
  .phone-image {
    height: 55vh;
    left: 15%;
    position: absolute;
    background-color: transparent;
    border-radius: 35px;
  }
  .title {
    font-size: 40px;
  }
  .main-subtitle {
  }
  .main-subtitle2 {
    font-size: 25px;
  }
  .text1 {
    font-size: 15px;
  }
  .text2 {
    font-size: 10px;
  }
}
@media (max-width: 540px) {
  .phone-image {
    height: 55vh;
    position: absolute;
    left: -15%;
    background-color: transparent;
    border-radius: 25px;
  }
}

@media (max-width: 440px) {
  .slider-right-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: fill;
    width: 50%;
  }
  .phone-image {
    height: 55vh;
    position: absolute;
    left: -25%;
    background-color: transparent;
    border-radius: 35px;
  }
  .title {
    font-size: 28px;
  }
  .main-subtitle {
  }
  .main-subtitle2 {
    font-size: 25px;
  }
}
@media (max-height: 1100px) {
  .slider-container-img-forvideo {
    height: 126vh !important;
  }
}
/* Video */
.slider-container-img-forvideo {
  background: radial-gradient(50% 50% at 50% 50%, #2d427f 0%, #11204a 100%);
  height: 100vh;
  width: 100%;
  background-image: url('../images/BG.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.mainvideo-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: 90vh;
  justify-content: center;
  align-self: center;
}
.mainvideo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title-container-head {
  color: #ffffff;
  width: 100%;
  display: flex;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  flex-direction: column;
  align-items: center;
}
.video-container {
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}
.video-wrapper {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.video {
  /*   width: 100%;
  height: 40vh; */
  padding: 20px;
}
.title-video {
  text-align: center;
  font-size: 48px;
}
.subtitle-video {
  font-size: 29.154px;
}
.vide-text-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  text-align: center;
  color: white;
  font-family: 'Open Sans', sans-serif;
}
@media (max-width: 400px) {
  .slider-container-img-forvideo {
    height: 120vh;
  }
}

@media (max-height: 740px) {
  .slider-right-text-container {
    height: 123vh;
  }
  .slider-container-img {
    height: 123vh;
  }
}

@media (max-width: 580px) {
  .video-wrapper iframe {
    height: 300px;
  }
  .img-container {
    width: 90% !important;
    height: 50px !important;
    position: relative !important;
    align-items: center !important;
    justify-content: flex-start !important;
  }
  .sound-icon {
    display: flex;
    align-items: flex-start;
  }
}
.img-container {
  position: absolute;
  width: 470px;
  height: 37vh;
  display: flex;
  float: left;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-end;
  cursor: pointer;
}
.sound-icon {
  width: 45px;
  height: 30px;
}
@media (min-height: 840px) {
  .img-container {
    width: 100%;
    align-items: center;
  }
}

.privacypolicy-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: black;
  color: #fff;
  font-size: 14px;
}
.privacypolicy-wrapper {
  width: 80%;
  padding-top: 10rem;
  padding-bottom: 10%;
}

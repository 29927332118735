.menu-container {
  display: grid;
  grid-template-columns: 95% 5%;
  position: absolute;
  width: 100%;
  z-index: 9 !important;
}
.menu-items-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 10vh;
  width: 100%;
}
.center-menu-items-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 10vh;
  width: 52.5%;
}
.logo {
  margin-top: 1.5%;
  margin-left: 3rem;
  width: 55px;
}
.menu-items {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.menu-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #ffffff !important;
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  padding-right: 1rem;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .menu-container {
    width: 100%;
    z-index: 9 !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .menu-items-wrapper {
    width: 100%;
    height: 15vh;
  }
  .logo {
    width: 55px;
    margin-left: 1rem;
  }
  .menu-items {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    margin-left: 1rem;
  }
  .menu-item {
    width: 100%;
    color: #ffffff !important;
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
  }
}
@media (max-width: 540px) {
  .menu-container {
    width: 100%;
    z-index: 9 !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .menu-items-wrapper {
    width: 100%;
    height: 15vh;
  }
  .logo {
    width: 50%;
    height: 7vh;
    margin-left: 1rem;
  }
  .menu-items {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: 1rem;
  }
  .menu-item {
    width: 100%;
    color: #ffffff !important;
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
  }
}

.waw-screen-container {
  width: 100%;
}
.waw-screen-card-image {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-self: center;
  align-content: center;
  height: 100vh;
}

.waw-screen-image {
  height: 70%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: relative;
  left: 5%;
}
.waw-screen-text-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-self: center;
  align-content: center;
}
.waw-screen-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  position: relative;
  right: 10%;
}
.waw-title {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 65px;
  width: 100%;
}
.waw-subtitle {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 32px;
  width: 70%;
  margin-bottom: 5%;
}
.waw-description {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  margin-bottom: 2%;
  text-align: left;
}
@media (min-width: 1024px) {
  .waw-screen-img {
    width: 80%;
  }
}
@media (max-width: 1024px) {
  .waw-screen-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .waw-screen-card-image {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
  .waw-screen-img {
    height: 70%;
    position: absolute;
  }
  .waw-screen-image {
    height: 50%;
    width: 50%;
    position: relative;
    left: 0px;
  }
  .waw-screen-text-wrapper {
    width: 80%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-bottom: 10%;
  }
  .waw-screen-text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-self: center;
    align-items: center;
    align-content: center;
    width: 80%;
    position: relative;
    right: 0px;
    text-align: center;
    height: 100%;
  }
  .waw-title {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 45px;
    width: 80%;
  }
  .waw-subtitle {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    width: 100%;
    margin-bottom: 5%;
  }
  .waw-description {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    width: 100%;
    margin-bottom: 2%;
    text-align: left;
    text-align: center;
  }
  .waw-screen-right-card-image {
    display: flex;
    flex-direction: column-reverse;
    justify-content: start;
    align-items: center;
    align-self: center;
    align-content: center;
    height: 100vh;
  }
  .waw-screen-right-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
    align-content: center;
    text-align: center;
    width: 80%;
    position: relative;
    left: 0px;
  }
}
@media (max-height: 1100px) and (max-width: 1024px) {
  .form-container {
    height: 130vh !important;
  }
  .waw-screen-container {
    height: 110vh;
  }
}
@media (max-height: 950px) and (max-width: 1024px) {
  .form-container {
    height: 150vh;
  }
}
@media (max-height: 850px) and (max-width: 1024px) {
  .form-container {
    height: 160vh;
  }
}

@media (max-width: 540px) {
  .waw-screen-container {
    display: flex;
    flex-direction: column;
    height: 120vh;
  }
  .waw-screen-card-image {
    display: flex;
    flex-direction: column;
  }
  .waw-screen-img {
    height: 80%;
    position: absolute;
    bottom: 10%;
  }
  .waw-screen-image {
    height: 70%;
    width: 100%;
    position: relative;
    left: 0px;
  }
  .waw-screen-text-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-self: center;
    align-items: center;
    align-content: center;
    margin-bottom: 5%;
  }
  .waw-screen-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
    align-content: center;
    width: 80%;
    position: relative;
    right: 0px;
    text-align: center;
  }
  .waw-title {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 45px;
    width: 80%;
  }
  .waw-subtitle {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
    width: 100%;
  }
  .waw-description {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 16px;
    width: 100%;
    margin-bottom: 2%;
    text-align: left;
    text-align: center;
  }
  .waw-screen-right-card-image {
    display: flex;
    flex-direction: column-reverse;
    justify-content: start;
    align-items: center;
    align-self: center;
    align-content: center;
    height: 100vh;
  }
  .waw-screen-right-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
    align-content: center;
    text-align: center;
    width: 80%;
    position: relative;
    left: 0px;
  }
}

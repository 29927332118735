.form-container {
  height: 90vh;
  background-color: #e3ecf1;
}
.form-card-image {
  display: flex;
  flex-direction: row;
  height: 90vh;
}
.form-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  width: 50%;
}
.form-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
}
.form-text-wrapper {
  height: 20vh;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  color: black;
  position: relative;
  bottom: 10%;
}
.form-title {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 65px;
  width: 100%;
}
.form-subtitle {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 29px;
  width: 100%;
}
.form-description {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 65px;
}
.form-input {
  width: 500px;
}

.ui.form .field .ui.input input {
  border: 1px solid #3d3d3d !important;
  box-sizing: border-box !important;
  border-radius: 50px !important;
  background-color: #e3ecf1;
}
.ui.form textarea {
  border: 1px solid #3d3d3d !important;
  box-sizing: border-box !important;
  border-radius: 25px !important;
  background-color: #e3ecf1 !important;
}
.form-text {
  width: 20%;
  position: relative;
  left: 4%;
  top: 0.5rem;
  background-color: #e3ecf1;
  text-align: center;
  z-index: 99;
}
.form-text-area {
  width: 20%;
  position: relative;
  left: 4.5%;
  top: 1rem;
  text-align: center;
  background-color: #e3ecf1 !important;
}
.form-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
}
.form-success-message {
  margin-top: 30px;
}
@media (max-width: 1024px) {
  .form-container {
    height: 110vh;
    background-color: #e3ecf1;
  }
  .form-card-image {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .form-image {
    width: 100%;
  }
  .form-text-wrapper {
    height: 20vh;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    align-content: center;
    color: black;
    position: relative;
    top: 10%;
  }
  .form-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .ui.form .field .ui.input input {
    border: 1px solid #3d3d3d !important;
    box-sizing: border-box !important;
    border-radius: 50px !important;
    background-color: #e3ecf1;
  }
  .ui.form textarea {
    border: 1px solid #3d3d3d !important;
    box-sizing: border-box !important;
    border-radius: 25px !important;
    background-color: #e3ecf1 !important;
  }
}

@media (max-width: 540px) {
  .form-container {
    height: 92vh !important;
    background-color: #e3ecf1;
  }
  .form-card-image {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .form-image {
    width: 100%;
  }
  .form-text-wrapper {
    height: 20vh;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    align-content: center;
    color: black;
    position: relative;
    top: 10%;
  }
  .form-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .ui.form .field .ui.input input {
    border: 1px solid #3d3d3d !important;
    box-sizing: border-box !important;
    border-radius: 50px !important;
    background-color: #e3ecf1;
  }
  .ui.form textarea {
    border: 1px solid #3d3d3d !important;
    box-sizing: border-box !important;
    border-radius: 25px !important;
    background-color: #e3ecf1 !important;
  }
}

.footer-container {
  width: 100%;
  height: 60vh;
  background: linear-gradient(173.46deg, #543285 5.14%, #07144e 94.86%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-self: center;
  align-items: center;
  align-content: center;
  text-align: center;
}
.footer-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 30%;
}
.footer-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.231818px;
  color: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.5;
  width: 35%;
}
.footer-text-secret {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.231818px;
  color: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.5;
  width: 35%;
  text-decoration-line: underline;
}
.footer-menu {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: #ffffff;
}
.footer-menu a {
  color: #ffffff;
}
.footer-menu a:hover {
  color: #ffffff;
  cursor: pointer;
}
.footer-icons {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.footer-icon-youtube {
  height: 30px;
  position: relative;
  bottom: 7.5%;
}
@media (max-width: 1024px) {
  .footer-container {
    width: 100%;
    height: 70vh !important;
    background: linear-gradient(173.46deg, #895091 5.14%, #1c1d61 94.86%);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-self: center;
    align-items: center;
    align-content: center;
    text-align: center;
  }
  .footer-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 30px;
    letter-spacing: -0.231818px;
    color: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.5;
    width: 70%;
  }
  .footer-text-secret {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 30px;
    letter-spacing: -0.231818px;
    color: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.5;
    width: 70%;
    text-decoration-line: underline;
  }
  .footer-menu {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color: #ffffff;
  }
  .footer-icons {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .footer-logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 12.5vh !important;
  }
  .footer-logo {
    height: 5vh;
    width: 100%;
  }
  .footer-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 3vh;
    width: 60%;
  }
  .footer-menu {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color: #ffffff;
    font-size: 24px;
  }
  .footer-container {
    height: 40vh;
  }
}
@media (max-width: 540px) {
  .footer-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.231818px;
    color: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.5;
    width: 85%;
    text-decoration-line: underline;
  }
  .footer-text-secret {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.231818px;
    color: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.5;
    width: 55%;
  }
  .footer-menu {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color: #ffffff;
    font-size: 14px;
  }
  .footer-icons {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .logo {
    height: 15vh;
    width: 100%;
  }
}

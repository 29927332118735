.ui.form .field.error input[type='text'] {
  background-color: #e3ecf1 !important;
}

/* .ui.form .field.field input:-webkit-autofill {
  background-color: #e3ecf1 !important;
  box-shadow: none !important;
}

.ui.form .field.field input:-webkit-autofill:focus {
  background-color: #e3ecf1 !important;
  box-shadow: none !important;
} */
.ui.form .field.field input:future {
  background-color: black !important;
}
